import React from 'react';

import { Dialog, DialogContent, TextField, FormControl, Paper } from '@material-ui/core';

import { moachartView, loginView, loginApplyClick, loginCloseClick, loginSubscribeClick } from 'utils/stat';

import { api } from 'settings.json';

import '../css/loginModal.scss';


class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            email: '',
        }
        this.isModalOpenChecked = false;
    }

    handleClose = (event) => {
        loginCloseClick();
        this.props.setAfterAuthTo('');
        moachartView(this.props.chart);
    }

    handleChange = (event) => {
        this.setState({
            email: event.target.value,
        })
    }

    handleLoginFormSubmit = (event) => {
        event.preventDefault();
        this.subscriberCheck(event);
    }

    handleSubscribeClick = (event) => {
        loginSubscribeClick();
        const xid = window.localStorage.getItem('xid');
        location.href = `https://intro.moa-report.com?from=chart&subscribe=t&from_stat=종합&xid=${xid}`
    }

    subscriberCheck = (event) => {
        fetch(api + '/subscribers/search?email=' + this.state.email, {
            mode: 'cors',
        })
        .then((resp) => resp.json())
        .then((data) => {
            if(data.result) {
                this.props.setEmail(this.state.email);
            } else {
                this.setState({
                    errorMessage: 'E-mail 주소를 다시 확인해주세요.',
                })
            }
        });

        loginApplyClick();
    }

    render() {
        const { needAuth, setAuth } = this.props;
        const { email, setEmail } = this.props;
        const { afterAuthTo, setAfterAuthTo } = this.props;

        const isLoginModalOpen = needAuth && email == '' && afterAuthTo !== '';

        if (isLoginModalOpen && !this.isModalOpenChecked) {
            loginView();
            this.isModalOpenChecked = true;
        }

        return (
            <div className='login-modal-root'>
                <Dialog open={isLoginModalOpen} fullWidth="sm" maxWidth="sm" onClose={this.handleClose}>
                    <DialogContent>
                        <div className="close-button" onClick={this.handleClose}>&times;</div>
                        <div className="modal-title">
                            <img src="/images/logo.svg" className="modal-logo"/><br />
                            <h1 className="modal-title">로그인</h1>
                        </div>
                        <div className="modal-description">
                            모아리포트를 구독하시는<br />
                            E-mail 주소로 로그인 가능합니다.
                        </div>
                        <div className="modal-form">
                            <form className="modal-form-body" noValidate autoComplete="off" onSubmit={this.handleLoginFormSubmit}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="modal-form-email"
                                        placeholder="구독시 입력한 E-mail 주소를 입력해주세요."
                                        variant="outlined"
                                        type="email"
                                        error={this.state.errorMessage !== ''}
                                        onChange={this.handleChange}
                                    />
                                </FormControl>
                            </form>
                        </div>
                        <div className="modal-error">
                            { this.state.errorMessage}
                        </div>
                        <div className="modal-information">
                            <ul>
                                <li>구독자가 아니실 경우 신규 구독 신청 후 바로 이용 가능합니다.</li>
                                <li>구독시 E-mail 주소에 오타가 있었을 경우 재 구독 후 이용 가능 합니다</li>
                            </ul>
                        </div>
                        <div className="modal-action">
                            <div className="button">
                                <Paper className="clickable filled-button" onClick={ this.handleSubscribeClick }>
                                    구독신청하기
                                </Paper>
                                <Paper className="clickable outlined-button" onClick={this.subscriberCheck}>
                                    확인
                                </Paper>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default LoginModal;