import React from 'react';
import { Paper } from '@material-ui/core';

import { ChartSelectButton } from 'components';

import '../css/chartSelectButtonGroup.scss';

class ChartSelectButtonGroup extends React.Component {
    render() {
        const { url, categories, selectedCategory, depth, label } = this.props;
        const { setSelectedCategory, fillCategories } = this.props;

        const items = categories.map(( each ) => {
            // const isActive = ('/chart/' + each.pk) === url  | selectedCategory.includes(each.final_parameter);
            const isActive = ('/chart/' + each.pk) === url;
            const isHighlight = selectedCategory[0] === each.final_parameter;
            return (
                <ChartSelectButton
                    buttonChart={each}
                    label={ each.name }
                    chartId={ each.pk }
                    key={ each.pk }
                    active={ isActive }
                    highlight={ isHighlight }
                    setSelectedCategory={ setSelectedCategory }
                    fillCategories={ fillCategories }
                    depth={ depth }
                    setAfterAuthTo={this.props.setAfterAuthTo}
                    currentChart={this.props.chart}
                />
            )
        });

        const buttonGroupName = (
            label === null || items.length === 0 ? '' : (
                <span className="button-group-name">
                    { label }
                </span>
            )
        );

        if(items.length === 0) {
            return (
                <div className="chart-select-button-group-root"></div>
            )
        }

        return (
            <div className="chart-select-button-group-root">
                <Paper square={true} elevation={0} className="selector-group">
                    { buttonGroupName }
                    { items }
                </Paper>
            </div>
        )
    }
}

ChartSelectButtonGroup.defaultProps = {
    label: null,
};


export default ChartSelectButtonGroup;