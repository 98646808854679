import React from 'react';
import { Redirect } from 'react-router-dom';

import { ChartCommon } from 'components';
import { getQueryVariable } from 'utils/url';

import { api } from 'settings.json';
import DefaultChartData from '../data/jsons/charts/default.json';

class Moachart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultChartURL: null,
        }
    }

    componentDidMount() {
        // fetch(api + '/charts/default', {
        //     mode: 'cors',
        // })
        // .then((resp) => resp.json())
        // import(`data/jsons/charts/default.json`).then(({default: data}) => data)
        // .then(
        //     (data) => {
        //         this.setState({
        //             defaultChartURL: '/chart/' + data.chart.pk,
        //         });
        //     }
        // );
        this.setState({
            defaultChartURL: '/chart/' + DefaultChartData.chart.pk,
        });
    }

    render() {
        // state가 변경되지 않으면 같은 url 계속 렌더링 -> 에러 발생
        const render_content = (
            this.state.defaultChartURL === null ?
                '' :
                <Redirect
                    path="*"
                    to={ this.state.defaultChartURL + this.props.location.search }
                />
        );

        return (
            <div>
                { render_content }
            </div>
        )
    }
}

export default Moachart;