import React from 'react';
import { Link } from 'react-router-dom';

import { Paper } from '@material-ui/core';

import { moachartSubchartClick } from 'utils/stat';

import '../css/chartSelectButton.scss';

class ChartSelectButton extends React.Component {
    constructor(props) {
        super(props);
        this.changeChart = this.changeChart.bind(this);
    }

    changeChart = (event) => {
        const { buttonChart, setSelectedCategory, fillCategories, chartId, depthAll } = this.props;

        // sfrom, from 값 바꿈, 인증 여부와 관게 없이 차트 이동 됨
        const currentFrom = window.sessionStorage.getItem('stat__from');
        window.sessionStorage.setItem('stat__sfrom', currentFrom);
        window.sessionStorage.setItem('stat__from', this.props.currentChart.chart_name_with_slash);

        moachartSubchartClick(buttonChart);

        setSelectedCategory(
            chartId,
            (categoryParameters) => {
                fillCategories(categoryParameters, depthAll);
            }
        );
    }

    render() {
        const { label, chartId, active, highlight, depth } = this.props;
        return (
            <div className={ `chart-select-button-root depth-${depth}` + (active ? ' active' : '') + (highlight ? ' highlight' : '') }>
                <Link to={'/chart' + ( chartId === null ? '' : ('/' + chartId) ) } className="chart-select-link" onClick={ this.changeChart }>
                    <span>{ label }</span>
                </Link>
            </div>
        )
    }
}

ChartSelectButton.defaultProps = {
    active: false,
    chartId: null,
};

export default ChartSelectButton;