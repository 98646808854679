import React from 'react';

import { Header, ChartSelectNav, ChartContent, LoginModal } from 'components';
import { getQueryVariable } from 'utils/url';

import { api } from 'settings.json';


class ChartCommon extends React.Component {
    constructor(props) {
        super(props);

        const sessionEmail = window.sessionStorage.getItem('email');

        this.state = {
            chart: null,
            chartContent: {
                start_date: '',
                end_date: '',
                pk: 0,
                items: [],
            },
            needAuth: false,
            email: (sessionEmail === null ? '' : sessionEmail),
            afterAuthTo: '',
        }
        this.pageFirstRendered = true;
        this.beforeChart = null;
    }

    componentDidMount() {
        const to = getQueryVariable('to');
        const share = getQueryVariable('share');

        const referrer = window.sessionStorage.getItem('stat__referrer');

        if(share === 't') {  // 공유로 들어왔고 접근 권한이 있는 차트일 경우
            let parsedChartId = this.props.url.split('/')[2];
            if(to !== null) {
                parsedChartId = to.split('/')[2];
            }
            // fetch(api + '/charts/' + parsedChartId, {
            //     mode: 'cors',
            //     headers: {
            //         Authorization: this.state.email,
            //     }
            // })
            // .then((resp) => resp.json())
            import(`data/jsons/charts/${parsedChartId}.json`).then(({default: data}) => data)
            .then((data) => {
                window.sessionStorage.setItem('stat__referrer', data.chart_name_with_slash);
            });
        } else if(referrer === null) {  // 직접 들어온 경우, null이 아닐 경우 페이지 새로고침 된 상태
            window.sessionStorage.setItem('stat__referrer', 'none');
        }

        if(to !== null) {
            const parsedChartId = to.split('/')[2];
            // fetch(api + '/charts/' + parsedChartId, {
            //     mode: 'cors',
            //     headers: {
            //         Authorization: this.state.email,
            //     }
            // })
            // .then((resp) => resp.json())
            import(`data/jsons/charts/${parsedChartId}.json`).then(({default: data}) => data)
            .then((data) => {
                if(data.need_auth) {
                    this.setState({
                        afterAuthTo: to,
                        needAuth: true,
                    });
                } else {
                    location.href = to;
                }
            });
        }

        // stat user_id값 불러오기
        window.sessionStorage.setItem('stat__user_id', this.state.email === '' ? 'none' : this.state.email);
    }

    setCurrentChart = (chart) => {
        if(chart.need_auth && this.state.email === '') {  // 인증이 필요하지만 로그인이 안 된 상태
            this.setState({
                needAuth: true,
            })
        } else {
            window.sessionStorage.setItem('currentChartName', chart.chart_name_with_slash);
            this.setState({
                chart: chart,
            })
        }
    }

    setCurrentChartContent = (content) => {
        this.setState({
            chartContent: content,
        })
        this.pageFirstRendered = true;
    }

    setNeedAuth = (needAuth) => {
        this.setState({
            needAuth: needAuth,
        });
    }

    setEmail = (email) => {
        this.setState({
            email: email,
        });
        window.sessionStorage.setItem('email', email);
        window.sessionStorage.setItem('stat__user_id', email);
        location.href = getQueryVariable('to') + (getQueryVariable('share') === 't' ? '?share=t' : '');
    }

    setAfterAuthTo = (to) => {
        this.setState({
            afterAuthTo: to,
        });
    }

    handlePageFirstRendered = (callback) => {
        if(this.state.chart === null) {
            return;
        }
        if(this.beforeChart === null || this.beforeChart.pk !== this.state.chart.pk) {
            this.beforeChart = this.state.chart;
            const loginModalOpen = window.sessionStorage.getItem('email') === null && this.state.chart.needAuth;
            if(!loginModalOpen) {
                callback(this.state.chart);
            }
        }
    }

    render() {
        return (
            <div>
                <Header chart={this.state.chart} url={this.props.url} />
                <ChartSelectNav
                    url={ this.props.url }
                    setCurrentChart={this.setCurrentChart}
                    setCurrentChartContent={this.setCurrentChartContent}
                    email={this.state.email}
                    setAfterAuthTo={this.setAfterAuthTo}
                    chart={this.state.chart}
                />
                <ChartContent
                    chart={this.state.chart}
                    chartContent={this.state.chartContent}
                    handlePageFirstRendered={this.handlePageFirstRendered}
                    needLogin={this.state.needAuth && this.state.email == '' && this.state.afterAuthTo !== ''}
                />
                <LoginModal
                    needAuth={this.state.needAuth}
                    setNeedAuth={this.setNeedAuth}
                    email={this.state.email}
                    setEmail={this.setEmail}
                    afterAuthTo={this.state.afterAuthTo}
                    setAfterAuthTo={this.setAfterAuthTo}
                    chart={this.state.chart}
                />
            </div>
        )
    }
}

export default ChartCommon;