import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Paper } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import Truncate from 'react-truncate';

import { Chart } from '../pages';

import { moachartView } from 'utils/stat';

import '../css/chartContent.scss';

class ChartContent extends React.Component {
    constructor(props) {
        super(props);
        // this.chartContent = [];
    }

    shouldComponentUpdate() {
        if(this.props.chart === null || this.props.needLogin) {
            return true;
        }
        this.props.handlePageFirstRendered(moachartView);
        return true;
    }

    render() {
        const { chart, chartContent } = this.props;

        if(chart === null || chart.latest_content_url === null) {
            return (
                <div></div>
            )
        }

        let chartBody = (
            chartContent.items.map((item) => (
                <TableRow key={item.rank + item.product_name}>
                    <TableCell align="center">{item.rank}</TableCell>
                    <TableCell align="left">
                        <img src={item.product_image} className="product-image"/>
                        <div className="product-name-group">
                            <img src={item.channel_image} className="channel-image"/>
                            <Truncate width={400}>{item.product_name}</Truncate>
                        </div>
                    </TableCell>
                    <TableCell align="center">{item.category}</TableCell>
                    <TableCell align="center">
                        <NumberFormat value={item.score} displayType="text" thousandSeparator={true} />
                    </TableCell>
                    <TableCell align="center">
                        <NumberFormat value={item.alarm_count} displayType="text" thousandSeparator={true} />
                    </TableCell>
                    <TableCell align="center">
                        <NumberFormat value={item.view_count} displayType="text" thousandSeparator={true} />
                    </TableCell>
                </TableRow>
            ))
        )

        if(chartContent.items.length == 0) {
            chartBody = (
                <TableRow>
                    <TableCell colSpan={6} className="noproduct">
                        <img src="/images/noproduct@2x.jpg" className="noproduct-img" /><br />
                        방송/판매된 상품의 종류가 5개 미만일 경우 <br />
                        차트가 노출되지 않습니다. <br />
                        <small>
                            (차트 집계일 기준)
                        </small>
                    </TableCell>
                </TableRow>
            )
        }

        return (
            <div className="chart-content-root">
                <div className="chart-channel-info">
                    *채널명 기준 : 복수 채널에서 판매 되었을 경우 가장 많이 판매된 채널 기준
                </div>
                 <TableContainer component={Paper}>
                    <Table className="table" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">순위</TableCell>
                                <TableCell align="left">상품명</TableCell>
                                <TableCell align="center">카테고리</TableCell>
                                <TableCell align="center">인기스코어</TableCell>
                                <TableCell align="center">알람설정수 <small>(회)</small></TableCell>
                                <TableCell align="center">상품조회수 <small>(회)</small></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chartBody}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

export default ChartContent;