import React from 'react';

import { Container } from '@material-ui/core';

import '../css/footer.scss';


class Footer extends React.Component {
    render() {
        if(this.props.pageName === 'trend') {
            return (<div></div>);
        }
        return (
            <div className="footer-root">
                <Container maxWidth="lg">
                    <div className="company-name">모아리포트</div>
                    <div className="copyright">
                        Copyright© 2020. BUZZNI All Rights Reserved.
                    </div>
                    <div className="company-information">
                            <span>사업자등록번호 : 134-86-43005</span>|
                            <span>통신판매업신고번호 : 2016-서울관악-0636</span>|
                            <span>대표이사 : 남상협, 김성국</span>|
                            <span>주소 : 서울특별시 관악구 남부순환로 1832 오선빌딩 버즈니</span>
                    </div>
                    <div className="links">
                        <ul>
                            <li>
                                <a href="https://buzzni.com" target="_blank">버즈니</a>
                            </li>
                            <li>
                                <a href="https://hsmoa.com" target="_blank">홈쇼핑모아</a>
                            </li>
                            <li>
                                <a href="https://ad.buzzni.com/?utm_source=moa-report.com&utm_medium=footer" target="_blank">모아애드</a>
                            </li>
                            <li>
                                <a href="https://recruit.buzzni.com" target="_blank">인재영입</a>
                            </li>
                            <li>
                                <a href="https://intro.moa-report.com?from=chart&subscribe=t" target="_blank">모아리포트 구독하기</a>
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Footer;
