import React from 'react';
import { Link } from 'react-router-dom';

import { trendView, trendMoachartClick } from 'utils/stat';

import '../css/trend.scss';

class Trend extends React.Component {
    componentDidMount() {
        trendView();
    }

    handleChartLinkClick = (event) => {
        const nextSfrom = window.sessionStorage.getItem('stat__from');
        const nextFrom = 'trend';
        
        window.sessionStorage.setItem('stat__sfrom', nextSfrom);
        window.sessionStorage.setItem('stat__from', nextFrom);
        
        trendMoachartClick();

        this.props.setPageName('moachart');
    }

    render() {
        return (
            <div className="trend-root">
                <h1>서비스 준비 중 입니다</h1>
                <div className="description">
                    한눈에 볼 수 있는 홈쇼핑 트렌드, 기대해주세요!
                </div>
                <img src="/images/comming_soon_big.svg" className="comming-soon-image" />
                <div className="links">
                    <Link to="/chart" onClick={this.handleChartLinkClick}>
                        <span>모아차트로 돌아가기 &gt;</span>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Trend;