import { observable, action, computed } from 'mobx';
import { autobind } from 'core-decorators';

@autobind
class SessionStore {
    @observable email = null;
    @observable accessToken = '';

    @action
    setSession(email) {
        const API_GET_TOKEN = 'http://localhost:8080/'  // TODO: FIX ME
        fetch(API_GET_TOKEN, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
                'email': email
            }),
        })
        .then(resp => resp.json())
        .then(data => {
            // {
            //      "result": true,
            //      "email": "email@example.com",
            //      "access_token": "xxxxx",
            // }
            if(result) {
                this.email = data.email;
                this.accessToken = data.access_token;
            }
        })
    }

    @computed
    getSession() {
        return {
            email: this.email,
            accessToken: this.accessToken,
        }
    }

    deleteSession() {
        const API_GET_TOKEN = 'http://localhost:8080/'  // TODO: FIX ME
        fetch(API_GET_TOKEN, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
                'email': email
            }),
        })
        .then(resp => resp.json())
        .then(data => {
            // {
            //      "result": true,
            // }
            if(result) {
                this.email = null;
                this.accessToken = '';
            }
        })
    }
}

export default SessionStore;