import React from 'react';

import { AppBar, Toolbar, Button, Tabs, Tab, withTheme } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import { autobind } from 'core-decorators';

import { gnbIntroClick, gnbMoachartClick, gnbTrendClick } from 'utils/stat';

import '../css/globalNavBar.scss';
import '../css/tab.scss';

class GlobalNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shadow: 0,
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    a11yProps = (name) => {
        return {
          id: `simple-tab-${name}`,
          'aria-controls': `simple-tabpanel-${name}`,
        };
      }

    handleChange = (event, newValue) => {
        const currentFrom = window.sessionStorage.getItem('stat__from');
        let nextFrom = '', nextSfrom = '';

        if(location.href.includes('/trend')) {  // trend에서 이동
            nextSfrom = currentFrom;
            nextFrom = 'trend';
        } else if(location.href.includes('/chart/')) {  // 모아차트에서 이동
            const currentFrom = window.sessionStorage.getItem('stat__from');
            nextSfrom = currentFrom;
            nextFrom = window.sessionStorage.getItem('currentChartName');
        }
        window.sessionStorage.setItem('stat__sfrom', nextSfrom);
        window.sessionStorage.setItem('stat__from', nextFrom);

        if(newValue === 'intro') {
            gnbIntroClick();
            const userId = window.sessionStorage.getItem('stat__user_id');
            const base64UserId = escape(btoa(userId));
            const xid = window.localStorage.getItem('xid');
            location.href = `https://intro.moa-report.com?from_stat=${nextFrom}&user=${base64UserId}&xid=${xid}`;
        } else if(newValue === 'moachart') {
            gnbMoachartClick();
            location.href = '/chart'
        } else if(newValue === 'trend') {
            gnbTrendClick();
            location.href = '/trend'
        }
    }

    handleScroll = (event) => {
        let shadow = 0;
        if(window.pageYOffset > 0) {
            shadow = 3;
        } else {
            shadow = 0;
        }
        this.setState({
            shadow: shadow,
        })
    }

    commingSoon = (menuName) => {
        return (
            <div className="comming-soon">
                <div className="imageBox">
                    <img src="/images/comming_soon@2x.png" className="image" />
                </div>
                <div className="content">{menuName}</div>
            </div>
        )
    }

    render() {
        return (
            <div className="gnb-root">
                <AppBar position="fixed" className="gnb" elevation={this.state.shadow} onScroll={this.handleScroll}>
                    <Toolbar>
                        <img src="/images/logo.svg" className="logo" />
                        <Tabs value={this.props.pageName} onChange={this.handleChange} aria-label="global navbar tabs" className="tabs">
                            <Tab label="서비스 소개" value="intro" className="tab" {...this.a11yProps('intro')} />
                            <Tab label="모아차트" value="moachart" className="tab" {...this.a11yProps('moachart')} />
                            <Tab label={this.commingSoon('홈쇼핑트렌드')} value="trend" className="tab" {...this.a11yProps('trend')} />
                        </Tabs>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default GlobalNavBar;