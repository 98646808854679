import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { Container } from '@material-ui/core';

import { Moachart, Chart, Trend } from 'pages';
import { GlobalNavBar, Footer } from 'components';
import { getQueryVariable } from 'utils/url';

import { api } from 'settings.json';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: location.href.includes('trend') ? 'trend' : 'moachart',
        }
    }

    componentDidMount () {
        let pageName = 'moachart';
        if(location.href.includes('trend')) {
            pageName = 'trend';
        }
        this.setPageName(pageName);

        const originalFrom = window.sessionStorage.getItem('stat__from');
        const originalSfrom = window.sessionStorage.getItem('stat__sfrom');
        if(getQueryVariable('from') !== null) {
            if(getQueryVariable('sfrom') !== null) {  // from 없이 sfrom만 있을 수는 없음
                window.sessionStorage.setItem('stat__sfrom', getQueryVariable('stat_sfrom'));
            } else {
                window.sessionStorage.setItem('stat__sfrom', originalFrom);
            }
            window.sessionStorage.setItem('stat__from', getQueryVariable('stat_from'));
        } else {
            if(originalFrom === null) {
                window.sessionStorage.setItem('stat__from', 'none');
            }
            if(originalSfrom === null) {
                window.sessionStorage.setItem('stat__sfrom', 'none');
            }
        }

        const queryXid = getQueryVariable('xid');
        if(queryXid !== null) {
            window.localStorage.setItem('xid', queryXid);
        }

        if(window.localStorage.getItem('xid') === null) {
            fetch(api + '/identifier/generate', {
                mode: 'cors',
            })
            .then((resp) => resp.json())
            .then((data) => {
                window.localStorage.setItem('xid', data.xid);
            })
        }
    }

    setPageName = (pageName) => {
        this.setState({
            pageName: pageName,
        })
    }

    render() {
        return (
            <div>
                <GlobalNavBar pageName={this.state.pageName} setPageName={this.setPageName} />
                <Container maxWidth="lg">
                    <Switch>
                        <Route exact path='/' component={() => {
                            window.location.href = 'https://intro.moa-report.com' + location.href.split('/')[3];
                            return null;
                        }}/>
                        <Route exact path="/chart" component={Moachart} />
                        <Route
                            path="/trend"
                            render={(props) => (
                                <Trend {...props} setPageName={this.setPageName} />
                            )}
                        />
                        <Route
                            path="/chart/:chart_id"
                            render={(props) => (
                                <Chart {...props} />
                            )}
                        />
                        <Redirect path="*" to="/chart" />
                    </Switch>
                </Container>
                <Footer pageName={this.state.pageName} />
            </div>
        );
    }
}

export default App;