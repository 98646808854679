import React from 'react';

import moment from 'moment';

import { Paper, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { moachartShareClick } from 'utils/stat';

import '../css/header.scss'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copyOpen: false,
        }
    }

    goToSubscribe = () => {
        const xid = window.localStorage.getItem('xid');
        location.href = `https://intro.moa-report.com/?from=chart&subscribe=t&xid=${xid}&from_stat=${this.props.chart.chart_name_with_slash}`;
    }

    copyLinkOpen = () => {
        this.setState({
            copyOpen: true,
        });
        moachartShareClick(this.props.chart);
    }

    copyLinkClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            copyOpen: false,
        })
    }

    render() {
        const { chart } = this.props;
        let updateTimeFormatted = '';
        let collection_period_display = ''
        if(chart !== null) {
            collection_period_display = chart.collection_period_display;
            if(chart.collection_period === 'daily') {
                updateTimeFormatted = moment(chart.latest_content_collected_at.start_date).format('YYYY.MM.DD');
            } else {
                const start_date = moment(chart.latest_content_collected_at.start_date).format('YYYY.MM.DD');
                const end_date = moment(chart.latest_content_collected_at.end_date).format('YYYY.MM.DD');
                updateTimeFormatted =  `${start_date} ~ ${end_date}` ;
            }
        }
        return (
            <div className="header-root">
                <h1>
                    모아차트
                </h1>
                <div className="description">
                    모든 홈쇼핑 채널에서 판매된 상품들의 인기순위로 매출, 판매수량과 사용자의 관심액션을 종합하여 순위를 선정합니다.<br />
                    종합차트, 카테고리 차트를 통해 모바일 홈쇼핑 인기상품과 트렌드를 파악 할 수 있습니다.
                    <div className="alert">
                        <div className="alert-body">
                            * 본 모아차트는 정식 서비스 오픈 전 운영되는 테스트 차트 입니다.
                            <small>
                                동일한 상품들의 매출, 유저액션을 모두 통합하여 랭킹을 산정 하고 있으며<br/>
                                판매처(방송사)별 상품을 노출 중인 홈쇼핑모아의 모아차트와는 랭킹이 다를 수 있습니다.
                            </small>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <div className="update-time-group">
                        <Paper className="action-item update-time" elevation={ 0 } square={ false }>
                            { updateTimeFormatted }<small>({collection_period_display})</small>
                        </Paper>
                        <small className="update-time-info">
                            * 데이터 업데이트 : 매일 오전 7시
                        </small>
                    </div>
                    <div className="clickable-item-box">
                        <CopyToClipboard text={`https://moa-report.com${this.props.url}?share=t`}>
                            <Paper className="action-item clickable" square={ false } onClick={ this.copyLinkOpen }>
                                <img src="/images/external-link.svg" className="icon" />
                                공유
                            </Paper>
                        </CopyToClipboard>
                    </div>
                    <div className="clickable-item-box">
                        <Paper className="action-item clickable" square={ false } onClick={ this.goToSubscribe }>
                            <img src="/images/mail.svg" className="icon" style={{width: '30px'}} />
                            구독
                        </Paper>
                    </div>
                </div>
                <Snackbar open={this.state.copyOpen} autoHideDuration={2000} onClose={this.copyLinkClose} anchorOrigin={{ vertical:'bottom', horizontal:'right' }}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.copyLinkClose} severity="info">
                        차트 링크가 복사되었습니다
                    </MuiAlert>
                </Snackbar>
            </div>
        )
    }
}

Header.defaultProps = {
    updateTime: null,
};

export default Header;