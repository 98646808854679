import React from 'react';

import { ChartCommon } from 'components';

class Chart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { chart_id } = this.props.match.params;
        return (
            <div>
                <ChartCommon url={this.props.location.pathname} />
            </div>
        )
    }
}

export default Chart;