import { stat } from 'settings.json';

const statFunctionBase = (url, body={}) => {
    fetch(`${stat}${url}?type=pretotype`, {
        method: 'POST',
        mode: 'cors',
        // cache: 'no-cache',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
    }).then((resp) => {return;});
}


// 모아차트
export const moachartView = (chart) => {
    statFunctionBase('/moachart/view', {
        chart: chart.chart_name_with_slash,
        user_id: window.sessionStorage.getItem('stat__user_id'),
        from: window.sessionStorage.getItem('stat__from'),
        sfrom: window.sessionStorage.getItem('stat__sfrom'),
        referrer: window.sessionStorage.getItem('stat__referrer'),
        xid: window.localStorage.getItem('xid'),
    });
}

export const moachartSubchartClick = (chart) => {
    statFunctionBase('/moachart/subchart/click', {
        chart: chart.chart_name_with_slash,
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

export const moachartShareClick = (chart) => {
    statFunctionBase('/moachart/share/click', {
        from: chart.chart_name_with_slash,
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}


// 로그인
export const loginView = () => {
    statFunctionBase('/login/view', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

export const loginApplyClick = () => {
    statFunctionBase('/login/apply/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

export const loginCloseClick = () => {
    statFunctionBase('/login/close/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

export const loginSubscribeClick = () => {
    statFunctionBase('/login/subscribe/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

// 홈쇼핑 트렌드
export const trendView = () => {
    statFunctionBase('/trend/view', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}
export const trendMoachartClick = () => {
    statFunctionBase('/trend/moachart/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}

// gnb
export const gnbIntroClick = () => {
    statFunctionBase('/gnb_menu/intro/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}
export const gnbMoachartClick = () => {
    statFunctionBase('/gnb_menu/moachart/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}
export const gnbTrendClick = () => {
    statFunctionBase('/gnb_menu/trend/click', {
        user_id: window.sessionStorage.getItem('stat__user_id'),
        xid: window.localStorage.getItem('xid'),
    });
}
