import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react'; // MobX 에서 사용하는 Provider
import App from 'shared/App';
import SessionStore from '../store/session';

const session = new SessionStore();

const Root = () => (
    <Provider session={session}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

export default Root;