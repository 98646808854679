import React from 'react';
import { Paper, Typography } from '@material-ui/core';

import { ChartSelectButton, ChartSelectButtonGroup } from 'components';
import { getQueryVariable } from 'utils/url';

import { api } from 'settings.json';
import DefaultChartData from '../data/jsons/charts/default.json';
import CategoryDepth1 from '../data/jsons/charts/category_depth_1.json';
import CategoryDepth2 from '../data/jsons/charts/category_depth_2.json';

import '../css/chartSelectNav.scss';

class ChartSelectNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // currentChart: null,
            categories: {
                depth_1: [],
                depth_2: [],
                // depth_3: [],
            },
            defaultChartURL: null,
        };
        this.currentChart = null;
        this.selectedCategory = [];
        this.currentChartContent = {};
        this.temporaryCategories = {
            depth_1: [],
            depth_2: [],
            depth_3: [],
        };
    }

    componentDidMount() {
        const url = (this.props.url === null ? this.state.defaultChartURL : this.props.url);
        const parsedChartId = url.split('/')[2];

        // fetch(api + '/charts/default', {
        //     mode: 'cors',
        //     headers: {
        //         Authorization: this.props.email,
        //     }
        // })
        // .then((resp) => resp.json())
        // import(`../data/jsons/charts/default.json`).then(({default: data}) => data)
        // .then(
        //     (data) => {
        //         this.setState({
        //             defaultChartURL: '/chart/' + data.chart.pk,
        //         });
        //         this.setSelectedCategory(
        //             parsedChartId,
        //             (categoryParameters) => {
        //                 this.fillCategories(categoryParameters);
        //             }
        //         );
        //     }
        // );
        this.setState({
            defaultChartURL: '/chart/' + DefaultChartData.chart.pk,
        });

        this.setSelectedCategory(
            parsedChartId,
            (categoryParameters) => {
                this.fillCategories(categoryParameters);
            }
        );
    }

    fillCategories = (categoryParameters, depth=1) => {
        const depth_2 = CategoryDepth2.charts[categoryParameters[0]] || [];
        this.setState({
            categories: {
                depth_1: CategoryDepth1.charts,
                depth_2: depth_2,
            }
        });
    }

    fetchChartContent = (chart) => {
        const { setCurrentChartContent } = this.props;

        if(chart === null || chart.latest_content_url === null) {
            return;
        }
        // fetch(chart.latest_content_url, {
        //     headers: {
        //         Authorization: this.props.email,
        //     }
        // })
        // .then((resp) => {
        //     if(resp.status === 403) {
        //         throw resp.status;
        //     }
        //     return resp.json()
        // })
        import(`data/jsons/contents/${chart.latest_content}.json`).then(({default: data}) => data)
        .then((data) => {
            // for json
            if(chart.need_auth && this.props.email === '') {
                throw 403  // need login
            }
            // end json

            // this.chartContent = data;
            setCurrentChartContent(data);
        }).catch((error) => {
            setCurrentChartContent({
                start_date: '',
                end_date: '',
                pk: 0,
                items: [],
            });  // 이전 차트 렌더링하지 않도록 빈 값 저장
            // TODO: 권한 없음 -> 로그인 요청
        });
    }

    setSelectedCategory = (chartId, callback=()=>{}) => {
        // fetch(api + '/charts/' + chartId, {
        //     mode: 'cors',
        //     headers: {
        //         Authorization: this.props.email,
        //     }
        // })
        // .then((resp) => resp.json())
        import(`data/jsons/charts/${chartId}.json`).then(({default: data}) => data)
        .then((data) => {
            this.selectedCategory =  data.category_parameters;
            if(!data.need_auth || this.props.email !== '') {
                callback(data.category_parameters);
            } else if(this.state.defaultChartURL !== null) {
                location.href = this.state.defaultChartURL + '?to=' + this.props.url + (getQueryVariable('share') === 't' ? '&share=t' : '');
            }
            // this.setState({
            //     currentChart: data,
            // });
            this.currentChart = data;
            this.props.setCurrentChart(data);
            this.fetchChartContent(data);
        });
    }

    render() {
        const url = (this.props.url === null ? this.state.defaultChartURL : this.props.url);
        return (
            <div className="chart-select-nav-root">
                <ChartSelectButtonGroup
                    url={ url }
                    categories={ this.state.categories.depth_1 }
                    selectedCategory={ this.selectedCategory }
                    setSelectedCategory={ this.setSelectedCategory }
                    depth={1}
                    fillCategories={ this.fillCategories }
                    setAfterAuthTo={this.props.setAfterAuthTo}
                    chart={this.props.chart}
                />
                <ChartSelectButtonGroup
                    url={ url }
                    categories={ this.state.categories.depth_2 }
                    selectedCategory={ this.selectedCategory }
                    setSelectedCategory={ this.setSelectedCategory }
                    // fillCategories={ this.fillCategories }
                    fillCategories={()=>{return}}
                    depth={2}
                    label='2-3차 카테고리'
                    setAfterAuthTo={this.props.setAfterAuthTo}
                    chart={this.props.chart}
                />
            </div>
        )
    }
}


ChartSelectNav.defaultProps = {
    url: null
}


export default ChartSelectNav;